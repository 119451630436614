import styled from 'styled-components'
import React from 'react'
import { Link } from 'gatsby'

const Intro = styled.div`
  padding: 70px 0 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  display: ${props => (props.mobile ? 'block' : 'none')};

  @media (min-width: 768px) {
    font-size: 16px;
    padding-top: 108px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    display: ${props => (props.mobile ? 'none' : 'block')};
  }

  p {
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
    color: #e8d8d9;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 19px;
    max-width: 300px;

    @media (min-width: 768px) {
      font-size: 16px;
      max-width: 404px;
    }

    @media (min-width: 1024px) {
      max-width: 490px;
      margin-bottom: 24px;
    }

    @media (min-width: 1200px) {
      font-size: 18px;

    }
  }

  a {
    color: #e8d8d9;
    font-family: ${({ theme }) => theme.headingFont};
    font-weight: 700;
    pointer-events: auto;
  }
`

const IntroMsg = ({ mobile }) => (
  <Intro mobile={mobile}>
    <p>
      I create bold 2D motion design & illustrations for brand, marketing &
      advertising.
    </p>

    <p>
      Click on 'work' or 'play' below to view some of my projects. The{' '}
      <Link to="/about/">about me</Link> page goes into a bit more detail, or if you're short on time, here’s my{' '}
      <Link to="/work/showreel/">showreel</Link>.
    </p>

    <p>
      <a href="mailto:me@belgiles.com">Drop me a line</a> if you’d like to chat about your projects or ideas.
    </p>
  </Intro>
)

export default IntroMsg
