import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import LazyLoad from 'react-lazyload'

const Tile = styled.li`
padding: 0;
font-size: 0;
margin: 0;
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px;

  @media (min-width: 600px) {
    float: left;
    width: 50%;
    padding: 15px;


  }
  @media (min-width: 1024px) {
    width: 33.33333%;
   
  }

  video {
    width: calc(100% + 2px);
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc(100% + 2px);
  }

  .rh5v-Overlay_component {
    display: none;
  }
`
export default ({ work, slug, count }) => {
  if (!work.frontmatter.cloudinary) {
    console.log(work)

    return (
      <Tile>
        <h3>
          {work.frontmatter.title} - no thumbnail or thumbnail path is wrong
        </h3>
      </Tile>
    )
  }

  return (
    <Tile>
      {work.frontmatter.cloudinary &&
        ['png', 'jpg'].some(v => work.frontmatter.cloudinary.includes(v)) && (
          <Link
            to={`${slug}${work.frontmatter.slug}/`}
            aria-label={work.frontmatter.title}
            style={{
              display: 'block',
              width: '100%',
              position: 'relative',
              paddingBottom: `${(work.frontmatter.height /
                work.frontmatter.width) *
                100}%`,
            }}
          >
            <img
              src={work.frontmatter.cloudinary}
              style={{ display: 'block', width: '100%', position: 'absolute', top: 0, left: 0, height: '100%' }}
              alt=""
            />
          </Link>
        )}

      {work.frontmatter.cloudinary?.includes('.mp4') && (
        <>
        <Link
          to={`${slug}${work.frontmatter.slug}/`}
          aria-label={work.frontmatter.title}
          style={{
            display: 'block',
            width: '100%',
            position: 'relative',
            paddingBottom: `${(work.frontmatter.height /
              work.frontmatter.width) *
              100}%`,
          }}
        >
          <VideoWorkaround count={count} slug={slug} title={work.frontmatter.title} src={work.frontmatter.cloudinary} />
        </Link>

  
        </>
      )}

      {work.frontmatter.cloudinary &&
        work.frontmatter.cloudinary.includes('.gif') && (
          <Link
            to={`${slug}${work.frontmatter.slug}/`}
            aria-label={work.frontmatter.title}
            style={{
              display: 'block',
              width: '100%',
              position: 'relative',
              paddingBottom: `${(work.frontmatter.height /
                work.frontmatter.width) *
                100}%`,
            }}
          >
            <img
              src={work.frontmatter.cloudinary}
              style={{ display: 'block', width: '100%' }}
              alt=""
            />
          </Link>
        )}

      {work.frontmatter.vimeo && (
        <Link
          to={`${slug}${work.frontmatter.slug}/`}
          aria-label={work.frontmatter.title}
          style={{
            display: 'block',
            width: '100%',
            position: 'relative',
            paddingBottom: `${(work.frontmatter.height /
              work.frontmatter.width) *
              100}%`,
          }}
        >
          <iframe
            src={`https://player.vimeo.com/video/354989034?autoplay=1&amp;loop=1&amp;autopause=0;`}
            width={work.frontmatter.height}
            height={work.frontmatter.height}
            frameBorder="0"
            allow="autoplay; fullscreen"
            style={{
              width: 'calc(100% + 1px)',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              height: 'calc(100% + 1px)',
              pointerEvents: 'none',
            }}
          ></iframe>
        </Link>
      )}
    </Tile>
  )
}

const VideoWorkaround = ({ src, count}) => {

  if(count < 2) {
    return <Video src={src} />
  }

 

  return (
    <>
    <LazyLoad height={200}  offset={100} once>
      <Video src={src} />
  
    </LazyLoad>
    </>
  )
}

const Video = ({ src }) => {

  return (

      <div
        dangerouslySetInnerHTML={{
          __html: `
            <video
              muted
              autoplay
              playsinline
              loop
              src="${src}"
            />
  `,
        }}
      />
  )
}
