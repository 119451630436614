export const aBaseColors = [
  '#FB500D',
  // '#28BBDF',
  '#FE9AD6',
  '#26252D',
  '#FFBE1D',
  // '#E8D8D9',
  '#019755',
  '#28BBDF',
  '#844a10'
  // '#FFFFFF',
]

let aColors = [
  {
    primaryColor: '#FB500D',
    secondaryColor: '#019755',
  },
  {
    primaryColor: '#FE9AD6',
    secondaryColor: '#26252D',
  },
  {
    primaryColor: '#FFBE1D',
    secondaryColor: '#26252D',
  },
  {
    primaryColor: '#FFBE1D',
    secondaryColor: '#844a10',
  },
  {
    primaryColor: '#019755',
    secondaryColor: '#FFBE1D',
  },

  {
    secondaryColor: '#28BBDF',
    primaryColor: '#FE9AD6',
  },
  {
    secondaryColor: '#28BBDF',
    primaryColor: '#844a10',
  },
  {
    secondaryColor: '#FE9AD6',
    primaryColor: '#26252D',
  },
  {
    secondaryColor: '#FFBE1D',
    primaryColor: '#28BBDF',
  },
  {
    secondaryColor: '#019755',
    primaryColor: '#FE9AD6',
  },
  {
    secondaryColor: '#019755',
    primaryColor: '#844a10',
  },
]

aColors.forEach(function(i) {
  const oGroup = {
    primaryColor: i.secondaryColor,
    secondaryColor: i.primaryColor,
  }
  aColors.push(oGroup)
})

export default aColors
