import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconVimeo } from '../images/icon-vimeo.svg'
import { ReactComponent as IconInsta } from '../images/icon-insta.svg'
import { ReactComponent as IconLinkedIn } from '../images/icon-linked.svg'
import { ReactComponent as IconShowreel } from '../images/icon-showreel.svg'

const Social = styled.ul`
  list-style-type: none;
  margin: 0 auto;
  margin-top: ${props => (props.home ? '14px' : '0')};
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 375px;

  @media (max-width: 767px) and (min-height: 580px) and (orientation: portrait) {
    ${props =>
      props.home &&
      `
        position: fixed;
        bottom: 25px;
        max-width: 375px;
        padding: 0 30px;
      `}
  }

  @media (min-width: 768px) {
    display: ${props => (props.home ? 'none' : 'inline-flex')};
  }

  @media (min-width: 1024px) {
    width: 260px;
    max-width: 260px;
    margin: 0;
  }

  li {
    display: inline-block;
    margin: 0;

    @media (min-width: 768px) {
      margin-left: 35px;
    }
  }
  path {
    fill: ${props => (props.home ? '#E8D8D9' : '#000')};
  }
  a {
    display: flex;
    align-itmes: center;
  }

  img {
    align-self: center;
  }
`

export default props => (
  <Social {...props}>
    <li>
      <a aria-label="Showreel" href="/work/showreel/">
        <IconShowreel />
      </a>
    </li>
    <li>
      <a
        aria-label="Instagram"
        href="https://www.instagram.com/bel_in_mo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInsta />
      </a>
    </li>
    <li>
      <a
        aria-label="Vimeo"
        href="https://vimeo.com/belgiles"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconVimeo />
      </a>
    </li>
    <li>
      <a
        aria-label="LinkedIn"
        href="https://www.linkedin.com/in/belgiles"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconLinkedIn />
      </a>
    </li>
  </Social>
)
