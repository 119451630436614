import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components'

const Figure = styled.figure`
  margin: 0;
  margin-bottom: 22px;

  @media (min-width:768px) {
    margin-top: 69px;
    margin-bottom: 69px;
  }

  .img__gif {
    display: block;
    width: 100%;
  }
`

export default ({
  src,
  alt,
  caption,
  creditType = 'Credit',
  creditLink = null,
  credit,
}) => (
  <StaticQuery
    // This seems like a really bad idea — we’re loading all available images
    // and filtering them _every time_. However, it’s the only thing I could
    // think of that allows fluid images in MDX. 😱
    query={graphql`
      {
        allFile(filter: { relativePath: { regex: "/.*.(jpg|png|gif)$/" } }) {
          totalCount
          edges {
            node {
              relativePath
              publicURL
              childImageSharp {
                fluid(maxWidth: 793, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const images = data.allFile.edges.map(({ node }) => node);
      const { publicURL, childImageSharp: imgData } =
        images.find(img => img.relativePath.includes(src)) || {};

      if ((!imgData || !imgData.fluid) && !publicURL) {
        if (process.env.NODE_ENV === 'development') {
          console.warn(`The image ${src} was not found.`);
        }

        return null;
      }

      return (
        <Figure>
          {imgData && imgData.fluid ? (
            <Image fluid={imgData.fluid} alt={alt} />
          ) : (
            <img src={publicURL} className="img__gif" alt={alt} />
          )}
          {(caption || credit) && (
            <figcaption className="figure__caption">
              {caption && (
                <span dangerouslySetInnerHTML={{ __html: caption }} />
              )}
              {credit && (
                <small className="figure__attribution">
                  {creditType}:
                  {creditLink ? (
                    <a className="figure__attribution-link" href={creditLink}>
                      {credit}
                    </a>
                  ) : (
                    <span className="figure__attribution-link">{credit}</span>
                  )}
                </small>
              )}
            </figcaption>
          )}
        </Figure>
      );
    }}
  />
);