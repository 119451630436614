import React from 'react'
import styled from 'styled-components'
import aColors, { aBaseColors } from '../utils/colors'
import { Link } from 'gatsby'
import Intro from './Intro'
import SocialList from './SocialList'

const Container = styled.div`
  width: 100%;
  overflow: hidden;

  @media (min-width: 1024px) {
    height: 100vh;
  }
`

const Square = styled.div`
  text-decoration: none;
  background: #26252d;
  color: #28bbdf;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  /* transition: all 200ms; */
  font-weight: 700;
  letter-spacing: -1.8px;

  @media (min-width: 768px) {
    font-size: 94px;
  }

  &.play {
    background: #019755;
    color: #fe9ad6;
    padding-bottom: 24px;

    @media (min-width: 1024px) {
      background: ${props => props.color1};
      color: ${props => props.color2};
    }
  }

  @media (min-width: 1024px) {
    background: ${props => props.color1};
    color: ${props => props.color2};
  }

  a.link {
    color: inherit;
    text-decoration: none;
    font-family: ${({ theme }) => theme.headingFont};
    font-weight: 700;
    margin: 14px 0 17px;

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  &.work {
    justify-content: flex-start;
    flex-direction: column;

    a.link {
      margin-top: 0;

      @media (min-width: 768px) {
        margin-bottom: 37px;
        margin-top: 24px;
      }
    }

    @media (min-width: 1024px) {
      height: 100vh;
      justify-content: center;
      left: 0;
    }
  }

  @media (min-width: 1024px) {
    will-change: transform;
    float: left;
    width: 50%;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 50%;
    transition: background 200ms ease 0s;
  }

  @media (min-width: 1280px) {
    font-size: 180px;
  }

  span {
    &.work {
      display: block;
    }
    @media (min-width: 1024px) {
      transition: background 200ms ease 0s;
    }
  }

  &:hover span {
    position: relative;
  }
`

export const SocialText = styled.ul`
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    position: absolute;
    z-index: 1000;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  @media (min-width: 1024px) {
    bottom: 120px;
  }

  li {
    margin-top: 10px;
    text-align: center;
  }

  a {
    color: #e8d8d9;
    font-size: 22px;
    font-family: ${({ theme }) => theme.headingFont};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @media (min-width: 1024px) {
      font-size: 26px;
    }
  }
`

export const Year = styled.div`
  display: none;
  color: #e8d8d9;
  font-size: 14px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
    bottom: 60px;
  }
`

let colorIndex = 0
let colorIndex2 = 0

class HomePanels extends React.Component {
  constructor(props) {
    super(props)

    colorIndex = this.generateRandom(aColors.length - 1)
    colorIndex2 = this.generateRandom(aColors.length - 1)

    this.state = {
      primaryColor: aColors[colorIndex].primaryColor,
      secondaryColor: aColors[colorIndex].secondaryColor,
      thirdColor: aColors[colorIndex2].secondaryColor,
      fourthColor: aColors[colorIndex2].primaryColor,
      activesquare: '',
    }
  }

  handleHover(square) {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return false
    }

    var aRandomColors = [].concat(aBaseColors)
    aRandomColors.sort(function() {
      return 0.5 - Math.random()
    })
    console.log('base', aBaseColors)
    console.log('copy', aRandomColors)
    console.log('------')

    colorIndex = this.generateRandom(aColors.length - 1, colorIndex)

    this.setState({
      primaryColor: aRandomColors[1],
      secondaryColor: aRandomColors[2],
      thirdColor: aRandomColors[3],
      fourthColor: aRandomColors[4],
      activeSquare: square
    })
  }
  generateRandom(max, exclude) {
    var num = Math.floor(Math.random() * (max - 0 + 1)) + 0
    return num === exclude ? this.generateRandom(0, max) : num
  }

  onMenuButtonClick = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    return (
      <Container
      // style={{background: this.state.bgColor}}
      >
        <Square
          className="work"
          type="work"
          activesquare={this.state.activeSquare}
          color1={this.state.primaryColor}
          color2={this.state.secondaryColor}
          onMouseEnter={() => this.handleHover('work')}
        >
          <Intro mobile />

          <Link to="/work/" className="link">
            <span className="work">work.</span>
          </Link>
        </Square>

        <Square
          className="play"
          type="play"
          color2={this.state.thirdColor}
          color1={this.state.fourthColor}
          activesquare={this.state.activeSquare}
          onMouseEnter={() => this.handleHover('play')}
        >
          <Link to="/play/" className="link">
            <span>play.</span>
          </Link>

          <SocialList home />
        </Square>

        <Intro />

        <SocialText>
          <li>
            <Link to="/work/showreel/">showreel</Link>
          </li>
          <li>
            <a
              aria-label="instagram"
              href="https://www.instagram.com/bel_in_mo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              instagram
            </a>
          </li>
          <li>
            <a
              aria-label="Vimeo"
              href="https://vimeo.com/belgiles"
              target="_blank"
              rel="noopener noreferrer"
            >
              vimeo
            </a>
          </li>
          <li>
            <a
              aria-label="LinkedIn"
              href="https://www.linkedin.com/in/belgiles"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin
            </a>
          </li>
        </SocialText>
        <Year>{new Date().getFullYear()}</Year>
      </Container>
    )
  }
}

export default HomePanels
