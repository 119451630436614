import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Cta = styled.div`
  background: ${props => props.play ? "#FB500D" : "#28BBDF"};
  overflow: hidden;
  margin-top: 50px;
  position: relative;

  &:hover {
    background: ${props => props.play ? "#FE9AD6" : "#019755"};

    a {
      color: ${props => props.play ? "#FB500D" : "#28BBDF"};

    }

    h3:after {
      color: ${props => props.play ? "#FB500D" : "#28BBDF"};

    }

  }

  a {
    color: ${props => props.play ? "#FE9AD6" : "#019755"};
    font-weight: bold;
    text-decoration: none;

   

    &:before {
      position: absolute;
      top: -100%;
      left: -100%;
      height: 300%;
      width: 300%;
      content: '';
    }

  }

  padding: 40px 0;

  @media (min-width:1024px) {
    padding: 75px 0 90px;
    margin-top: 100px;
  }
`

const Col = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;

  @media (min-width: 1240px) {
    padding: 0;
  }
`

const Title = styled.h3`
  font-size: 40px;
  margin-top: 0;
  letter-spacing: -1.2px;
  color: ${props => props.play ? "#FE9AD6" : "#019755"};

  margin: 0;

  &:after {
    content: '.';
    color: inherit;
  }

  @media (min-width: 1024px) {
    font-size: 100px;
  }

  @media (min-width:1240px) {
    transform: translateX(-3px);
  }
`

const Description = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.35;

  @media (min-width: 768px) {
    margin-bottom: 18px;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
`

export default ({play, work, title, description, link }) => (
  <Cta play={play} work={work}>
    <Col>
      <Title play={play} work={work}><Link to={link}>{title}</Link></Title>
      {/* <Description>{description}</Description> */}

      {/* <Link to={link}>Let's {title}</Link> */}
    </Col>

  </Cta>
)
