import React from 'react'
import styled from 'styled-components'
import SocialList from './SocialList'
import { Link } from 'gatsby'

const Footer = styled.footer`
  padding: 26px 20px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    padding: 36px 35px;
  }
`

export const LogoType = styled.h4`
  font-size: 30px;
  line-height: 1;
  letter-spacing: -0.28px;
  color: #26252d;
  margin: 0;
  position: relative;
  z-index: 2;
  text-transform: lowercase;
  display: none;

  @media (min-width: 1024px) {
    display: inline;
  }

  a {
    color: #26252d;
    text-decoration: none;
  }
`

export default () => (
  <Footer>
    <LogoType>
      <Link to="/">bel.</Link>
    </LogoType>

    <SocialList />
  </Footer>
)
