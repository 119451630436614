import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Masonry from 'react-masonry-component'
import IndexCTA from './IndexCTA'
import Tile from './Tile'

const masonryOptions = {
  transitionDuration: 0,
}

const WorkGrid = styled.div`
  max-width: 1680px;
  margin: 0 auto;

  .my-gallery-class {
    padding: 0;
    margin: 0;
    list-style: none;

    @media (min-width: 600px) {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }
`

const Title = styled.h3`
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 48px;
    font-size: 60px;
    margin-top: 90px;
  }
`

export default ({ noHeading }) => (
  <StaticQuery
    query={graphql`
      {
        allMdx(
          filter: {
            fileAbsolutePath: { glob: "**/play/**/*.{md,mdx}" }
            frontmatter: { hidden: { ne: true } }
          }
          sort: { fields: fileAbsolutePath, order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                width
                height
                title
                slug
                cloudinary
              }
            }
          }
        }
      }
    `}
    render={data => {
      const work = data.allMdx.edges.map(({ node }) => node)
      return (
        //
        <>
          <WorkGrid>
            {!noHeading && <Title>more play.</Title>}
            <Masonry
                enableResizableChildren={true}
              elementType={'ul'}
              className={'my-gallery-class'} // default ''
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            >
              {work.map((work, index) => {
                return (
                  <Tile work={work} slug="/play/" count={index} key={index} />
                )
              })}
            </Masonry>
          </WorkGrid>

          <IndexCTA
            title="view work"
            description="Time to get those pixels moving."
            link="/work/"
          />
        </>
      )
    }}
  />
)
