import styled from 'styled-components'
import Img from 'gatsby-image'


export const Col = styled.div`
  @media (min-width: 768px) {
    position: relative;
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
    flex: 0 0 auto;
  }
`

export default styled.div`
  max-width: ${props => props.variation === 'aboutColor' ? '1200px' : '1680px'};
  margin: 0 auto;
  padding: 48px 30px 0;
  display: flex;
  position: relative;
  z-index: 10000;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 1240px) {
    padding: 68px 0 0 30px;
  }
  @media (min-width: 1740px) {
    padding-left: 0;
  }

  h1 {
    font-size: 70px;
    margin-top: 0;
    letter-spacing: -1.2px;
    margin: 0 0 10px;
    position: relative;
    left: -1px;
    color: ${props => props.theme[props.variation]};

    &:after {
      content: '.';
      color: inherit;
    }

    @media (min-width: 768px) {
      font-size: 70px;
      line-height: 1;
      margin-bottom: 30px;
    }

    @media (min-width: 1200px) {
      font-size: 120px;
      line-height: 1;
      margin: 0 0 35px;
    }

    @media (min-width: 1360px) {
      font-size: 180px;
      line-height: 1;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.57;
    margin: 0 0 25px;

    @media (min-width: 1024px) {
      font-size: 16px;
    }

    &:last-child {
      margin: 0 0 40px;

      @media (min-width: 1024px) {
        margin-bottom: 55px;
      }
    }
  }

  a {
    color: #000000;
    font-weight: bold;
    text-decoration: none;
  }
`
