import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Video = styled.div`
  background: #000;
  position: relative;
  padding-bottom: ${props => props.ratio ? props.ratio : " 56.25%"} ;
  margin-bottom: 22px;
  @media (min-width:768px) {
    margin-bottom: 69px;
    margin-top: 69px;
  }

  > .reactPlayer {
    position: absolute;
  }
`

export default ({ url,ratio, loop }) => (
  
  <Video ratio={ratio}>
    <ReactPlayer className="reactPlayer" url={url} width="100%" loop={loop ? true : false} height="100%" controls />
  </Video>
)
