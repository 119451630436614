import React from 'react'

import styled from 'styled-components'

import { Figure, VideoEmbed } from '$components'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const TwoColumns = styled.div`
  margin-top: 22px;
  margin-bottom: 22px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
    margin-bottom: 69px;
  }
`

const Column = styled.div`
  figure {
    margin: 0;
  }


  video {
    object-fit: cover;
    height: 100%;
    display: block;
  }
`

const PortraitTiles = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
  }

  figure {
    margin: 0;
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
  }

  video {
    object-fit: cover;
    height: 100%;
    display: block;
  }
`

const Portrait = styled.div`
  grid-column: 1;
  grid-row: 1 / span 2;
`

const PortraitTopRight = styled.div`
  grid-column: 2;
  grid-row: 1;
  background: grey;
  position: relative;
`
const PortraitBottomRight = styled.div`
  grid-column: 2;
  grid-row: 2;
  background: grey;
  position: relative;
`

const ImageTiles = ({ tile1, tile2, video, template }) => {
  if (template === 'portrait') {
    return (
      <>
        <PortraitTiles>
          <Portrait>
            <VideoEmbed src={video} />
          </Portrait>
          <PortraitTopRight>
            {tile1.includes('.mp4') ? (
              <VideoEmbed src={tile1} />
            ) : (
              <Figure src={tile1} />
            )}
          </PortraitTopRight>

          <PortraitBottomRight>
            {tile2.includes('.mp4') ? (
              <VideoEmbed src={tile2} />
            ) : (
              <Figure src={tile2} />
            )}
          </PortraitBottomRight>
        </PortraitTiles>
      </>
    )
  }

  return (
    <>
      <TwoColumns>
        <Column>
          {tile1.includes('.mp4') ? (
            <VideoEmbed src={tile1} />
          ) : (
            <Figure src={tile1} />
          )}
        </Column>
        <Column>
          {tile2.includes('.mp4') ? (
            <VideoEmbed src={tile2} />
          ) : (
            <Figure src={tile2} />
          )}
        </Column>
      </TwoColumns>
    </>
  )
}

export default ImageTiles
