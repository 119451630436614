import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components'

const Video = styled.div`

  overflow: hidden;



  video {
    transform: scale(1.01);


  }
  
`

export default ({
  src,
  alt,
  caption,
  creditType = 'Credit',
  creditLink = null,
  credit,
}) => (
  <StaticQuery
    // This seems like a really bad idea — we’re loading all available images
    // and filtering them _every time_. However, it’s the only thing I could
    // think of that allows fluid images in MDX. 😱
    query={graphql`
      {
        allFile(filter: { relativePath: { regex: "/.*.(mp4|mov)$/" } }) {
          totalCount
          edges {
            node {
              relativePath
              publicURL
            }
          }
        }
      }
    `}
    render={data => {
      const images = data.allFile.edges.map(({ node }) => node);
      let { publicURL } =
        images.find(img => img.relativePath.includes(src)) || {};

      if(src.includes('.cloudinary')) {
        publicURL = src;
      }

      if (!publicURL) {
        if (process.env.NODE_ENV === 'development') {
          console.warn(`The video ${src} was not found.`);
        }

        return null;
      }

      return (

        <>
          <Video>
          <video style={{width: '100%', display: "block"}} autoPlay muted playsInline loop >
            <source src={publicURL} type="video/mp4" />
          </video>
          </Video>
        </>

      );
    }}
  />
);