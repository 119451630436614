import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import React, { useState } from 'react'
import logo from '../images/bel-logo.svg'

const TopBar = styled.header`
  background: #26252d;
  padding: 16px 30px;
  display: flex;
  z-index: 999999;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  @media (min-width: 1024px) {
    padding: 25px 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Logo = styled.img`
  display: block;
  position: relative;
  left: -10px;
`

const Nav = styled.ul`
  padding: 0;
  margin: 0;
  display: inline-flex;
  background: #26252d;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 76px 0 10px;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.5s ease-in-out;

  @media (min-width: 1024px) {
    transform: none;
    padding: 0;
    transition: none;
    position: static;
    flex-direction: row;
    width: auto;
  }

  

  li {
    margin-left: 0;
    text-align: center;
    margin-bottom: 34px;
    line-height: 1;

    @media (min-width: 1024px) {
      margin-left: 60px;
      margin-bottom: 0;
    }
  }
`

export const NavLink = styled(Link)`
  color: ${props => props.theme[props.variation]};
  font-size: 26px;
  font-family: ${({ theme }) => theme.headingFont};
  text-decoration: none;
  font-weight: 700;
  letter-spacing: -0.28px;
  line-height: 1;

  @media (min-width: 1024px) {
    font-size: 16px;
  }

  &:after {
    content: '.';
  }
`

export const LogoType = styled.h1`
  font-size: 30px;
  line-height: 1;
  letter-spacing: -0.28px;
  color: #E8D8D9;
  margin: 0;
  position: relative;
  z-index: 2;
  text-transform: lowercase;

  @media (min-width: 1024px) {
    font-size: 34px;

  }

  a {
    color: #E8D8D9;
    text-decoration: none;
  }
`

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 28px;
  height: 28px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  position: relative;
  z-index: 2;

  &:focus {
    outline: none;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  div {
    width: 28px;
    height: 3px;
    background: #E8D8D9;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

const Header = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <TopBar>
      {/* <Logo src="https://res.cloudinary.com/belgiles/image/upload/q_auto:best/v1571141195/belgiles.com/bel-white_emkxlj.gif" width="80" alt="Bel." /> */}
      <LogoType>
        <Link to="/">bel.</Link>
      </LogoType>

      <Nav open={open}>
        <li>
          <NavLink to="/work/showreel/" variation="showreelColor">
            showreel
          </NavLink>
        </li>
        <li>
          <NavLink to="/work/" variation="workColor">
            work
          </NavLink>
        </li>
        <li>
          <NavLink to="/play/" variation="playColor">
            play
          </NavLink>
        </li>
        <li>
          <NavLink to="/about/" variation="aboutColor">
            about
          </NavLink>
        </li>
        <li>
          <NavLink as="a" href="mailto:me@belgiles.com" variation="contactColor">
            contact me
          </NavLink>
        </li>
      </Nav>
      <Burger open={open} setOpen={setOpen} />
    </TopBar>
  )
}

export default Header
