import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import './layout.css'
import '../fonts/maisonneueextended-black/stylesheet.css'
import styled from 'styled-components'

import { ThemeProvider } from 'styled-components'
import theme from '../utils/theme'
import { MDXProvider } from "@mdx-js/react"

import { ImageTiles, Figure, VideoEmbed, Video } from './'

const shortcodes = { ImageTiles, Figure, VideoEmbed, Video }

export const NavSpacer = styled.div`
  height: 62px;

  @media (min-width: 1024px) {
    height: 84px;
  }
`;


const Layout = ({ children, noFooter, noHeader }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <MDXProvider components={shortcodes}>
          <ThemeProvider theme={theme}>
            <>
              {!noHeader && (
                <>
                  <NavSpacer />
                  <Header siteTitle={data.site.siteMetadata.title} />
                </>

              )
              }
              <div>{children}</div>

              {!noFooter && <Footer />}
            </>
          </ThemeProvider>
        </MDXProvider>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
