export default {
  maxWidth: '1200px',
  secondaryColor: '#FD4D51',
  workColor: '#43AB48',
  playColor: '#FE9AD6',
  workColor: '#43AB48',
  aboutColor: '#FFBE1D',
  showreelColor: '#28BBDF',
  contactColor: '#FB500D',
  headingFont: `"Maison Neue", sans-serif`
}